<template>
  <div v-if="loading">
    <clip-loader :loading="loading" color="primary"></clip-loader>
  </div>
  <div v-else>
    <div class="p-4" v-if="item">
      <div class="clearfix">
        <div class="aab-contract-infos-title">Informations générales</div>
        <div class="mt-4 mb-4 aab-contract-infos-block-bg">VOS RÉFÉRENCES</div>
        <vs-row vs-w="12" class="">
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full">
              <p class="aab-contract-infos-desc">
                <span>Numéro client : </span>
                <strong v-if="dataProject.client">{{ dataProject.client.username }}</strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Numéro contrat : </span>
                <strong>{{ dataProject.refContrat }}</strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Date d'effet : </span>
                <strong>{{ startDateContributions }}</strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Intermédiaire d'assurance : </span>
                <strong v-if="dataProject.broker">
                  <span v-if="dataProject.broker.personality === 'particular'">
                    {{ dataProject.broker.person.firstname }}
                    {{ dataProject.broker.person.lastname }}
                    <span v-if="dataProject.broker.person.forSociety">
                      ({{ dataProject.broker.person.forSociety }})
                    </span>
                  </span>
                  <span v-else-if="dataProject.broker.personality === 'company'">
                    {{ dataProject.broker.person.forSociety }}
                    ({{ dataProject.broker.person.firstname }}
                    {{ dataProject.broker.person.lastname }})
                  </span>
                </strong>
              </p>
            </div>
          </vs-col>

          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full">
              <p class="aab-contract-infos-desc">
                <span>Souscripteur : </span>
                <strong v-if="dataProject.subscription">
                  {{ dataProject.subscription.subscriber.firstname }}
                  {{ dataProject.subscription.subscriber.lastname }}
                </strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Durée des cotisations (année) : </span>
                <strong>{{ propositionChoose.corverageDuration }}</strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Date d'échéance : </span>
                <strong>{{ endDateContributions }}</strong>
              </p>
              <p></p>
            </div>
          </vs-col>
        </vs-row>
        <div class="mt-4 mb-4 aab-contract-infos-block-bg">
          VOTRE COUVERTURE
        </div>
        <vs-row vs-w="12" class="">
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full">
              <p class="aab-contract-infos-desc">
                <span>Périodicité des cotisations : </span>
                <strong>{{
                  onPeriodicityOfPayment(propositionChoose.periodicityOfPayment)
                }}</strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Cotisation périodique : </span>
                <strong
                  >{{ onFormatAmount(dataProject.quittances[1].cotisationTTC) }} F
                  CFA</strong
                >
              </p>
              <p class="aab-contract-infos-desc">
                <span>Rente annuelle à servir sur 5 ans : </span>
                <strong>
                  {{ onFormatAmount(propositionChoose.amountOfCapital) }} F
                  CFA</strong
                >
              </p>
            </div>
          </vs-col>

          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <div class="w-full">
              <p class="aab-contract-infos-desc">
                <span>Nombre de cotisations : </span>
                <strong>{{ dataProject.quittances.length }}</strong>
              </p>
              <p class="aab-contract-infos-desc">
                <span>Cumul des cotisations : </span>
                <strong
                  >{{ onFormatAmount(onCumulTotal(dataProject.quittances)) }} F
                  CFA</strong
                >
              </p>
            </div>
          </vs-col>
        </vs-row>
        <div v-if="dataProject.filesname">
          <div class="mt-4 mb-4 aab-contract-infos-block-bg-large">
            Télécharger les documents contractuels
          </div>
          <vs-row vs-w="12">
            <vs-col
              class="mb-2"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="12"
            >
              <div class="w-full">
                <span :key="index" v-for="(elFile, index) in dataProject.filesname">
                  <vs-button
                    class="mr-4 mb-4"
                    type="border"
                    @click="checkViewFile(elFile)"
                  >
                    {{ elFile.name }}
                  </vs-button>
                </span>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "aab-vie-suscribe-general-informations",
  components: {
    ClipLoader,
  },
  props: {
    crrModuleName: String,
    item: String,
    dataProject: Object,
  },
  data() {
    return {
      loading: false,
      propositionChoose: {},
      startDateContributions: "",
      endDateContributions: "",
    };
  },
  mounted() {
    this.initDataLocal()
  },
  computed: {
    ...mapGetters("general", ["rowsTable", "allPeriodicityOfPayment"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    async initDataLocal() {
      if (this.item) { // ID of project
        this.propositionChoose = this.dataProject.quote.aboutProposition.data.find(
          (el) => el.id == this.dataProject.quote.aboutProposition.selectedId
        )
        this.startDateContributions = mixin.methods
          .formatDate(this.dataProject.quittances[0].startDateContributions)

        this.endDateContributions = mixin.methods
          .formatDate(
            this.dataProject.quittances[this.dataProject.quittances.length - 1]
              .endDateContributions
          )
      }
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    checkViewFile(el) {
      window.open(this.showFileData(el.filename), "_blank");
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    onPeriodicityOfPayment(period) {
      let x = this.allPeriodicityOfPayment.find((el) => el.id === period);
      if (x) return x.label;
    },
    onFormatAmount(amount) {
      if (amount) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return 0;
      }
    },
    onCumulTotal(quittances) {
      let total = 0;

      if (quittances.length) {
        quittances.forEach((el) => {
          total += parseInt(el.cotisationTTC);
        });

        total = parseInt(total) - this.propositionChoose.accessoir;
      }

      return total;
    },
  },
  watch: {
    item() {
      this.initDataLocal()
    },
  },
};
</script>
<style scoped>
.aab-contract-infos-title {
  color: rgba(var(--vs-primary), 1);
  font-size: 22px;
  font-weight: bold;
}
.aab-contract-infos-desc {
  font-size: 16px;
  margin-bottom: 5px;
}
.aab-contract-infos-block-bg {
  background: rgba(var(--vs-primary), 1);
  padding: 7px;
  color: white;
  font-size: 19px;
  font-weight: bold;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  text-align: center;
  width: 280px;
}
.aab-contract-infos-block-bg-large {
  background: rgba(var(--vs-primary), 1);
  padding: 7px;
  color: white;
  font-size: 19px;
  font-weight: bold;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  text-align: center;
  width: 600px;
}
</style>